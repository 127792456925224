<template>
     <div class="card page-bottom b-r-0">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="info-block">
                      <ul class="pagination pagination-primary">
                        <li class="page-item disabled"><a class="page-link" href="javascript:void(0)" tabindex="-1">Previous</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                        <li class="page-item active"><a class="page-link" href="javascript:void(0)">2 <span class="sr-only"></span></a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">Next</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 text-end">
                    <div class="pagination-content"><span class="f-w-600">Showing Products 1 - 24 Of 200 Results</span></div>
                  </div>
                </div>
              </div>
</template>