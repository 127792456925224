<template>
     <div class="box-col-6 dash-lg-50 col-md-6 col-xl-4">
                <div>
                  <div class="card">
                    <div class="card-header pb-0">
                      <div class="media">
                        <div class="media-body">
                          <h5>Recent Activity</h5>
                        </div>
                        <div class="icon-box onhover-dropdown"><vue-feather type="more-horizontal"></vue-feather>
                          <div class="icon-box-show onhover-show-div">
                            <ul> 
                              <li> <a>
                                   Done</a></li>
                              <li> <a>
                                   Pending</a></li>
                              <li> <a>
                                   Rejected</a></li>
                              <li> <a>In Progress</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="activity-media">
                        <div class="media">
                          <div class="recent-circle bg-primary"></div>
                          <div class="media-body">
                            <h6 class="font-roboto">Any desktop publishing pack ages editors.</h6><span><i class="me-2" data-feather="clock"><vue-feather type="clock"></vue-feather></i><span class="font-roboto">25 July 2023 | 20 hours ago</span></span>
                          </div>
                        </div>
                        <div class="media">
                          <div class="recent-circle bg-primary"></div>
                          <div class="media-body">
                            <h6 class="font-roboto">
                              Contrary to popular belief, Lorem Ipsum
                              is not simply.
                            </h6><span><i class="me-2" data-feather="clock"><vue-feather type="clock"></vue-feather></i><span class="font-roboto">25 July 2023 | 20 hours ago</span></span>
                          </div>
                        </div>
                        <div class="media">
                          <div class="recent-circle bg-primary"></div>
                          <div class="media-body">
                            <h6 class="font-roboto">Established fact that a reader will be distracted lorem.</h6><span><i class="me-2" data-feather="clock"><vue-feather type="clock"></vue-feather></i><span class="font-roboto">25 July 2023 | 20 hours ago</span></span>
                          </div>
                        </div>
                        <div class="media">
                          <div class="recent-circle bg-primary"></div>
                          <div class="media-body">
                            <h6 class="font-roboto">Established fact that a reader will be distracted lorem.</h6><span><i class="me-2" data-feather="clock"><vue-feather type="clock"></vue-feather></i><span class="font-roboto">25 July 2023 | 20 hours ago</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>