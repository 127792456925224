<template>
     <div class="col-md-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Square checkbox</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <div class="checkbox checkbox-dark m-squar">
                            <input id="inline-sqr-1" type="checkbox">
                            <label class="mt-0" for="inline-sqr-1">Default</label>
                          </div>
                          <div class="checkbox checkbox-dark m-squar">
                            <input id="inline-sqr-2" type="checkbox" disabled="">
                            <label for="inline-sqr-2">Disabled</label>
                          </div>
                          <div class="checkbox checkbox-dark m-squar">
                            <input id="inline-sqr-3" type="checkbox" checked="">
                            <label for="inline-sqr-3">checked</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <h5>Solid checkbox</h5>
                      </div>
                      <div class="col">
                        <div class="m-t-15">
                          <div class="form-check checkbox checkbox-solid-success">
                            <input class="form-check-input" id="solid1" type="checkbox">
                            <label class="form-check-label" for="solid1">Default</label>
                          </div>
                          <div class="form-check checkbox checkbox-solid-dark">
                            <input class="form-check-input" id="solid2" type="checkbox" disabled="">
                            <label class="form-check-label" for="solid2">Disabled</label>
                          </div>
                          <div class="form-check checkbox checkbox-solid-primary">
                            <input class="form-check-input" id="solid3" type="checkbox" checked="">
                            <label class="form-check-label" for="solid3">checked</label>
                          </div>
                          <div class="form-check checkbox checkbox-solid-danger">
                            <input class="form-check-input" id="solid4" type="checkbox" checked="">
                            <label class="form-check-label" for="solid4">checked</label>
                          </div>
                          <div class="form-check checkbox checkbox-solid-light">
                            <input class="form-check-input" id="solid5" type="checkbox" checked="">
                            <label class="form-check-label" for="solid5">checked</label>
                          </div>
                          <div class="form-check checkbox checkbox-solid-info">
                            <input class="form-check-input" id="solid6" type="checkbox" checked="">
                            <label class="form-check-label" for="solid6">checked</label>
                          </div>
                          <div class="form-check checkbox checkbox-solid-dark">
                            <input class="form-check-input" id="solid7" type="checkbox" checked="">
                            <label class="form-check-label mb-0" for="solid7">checked</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>