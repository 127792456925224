<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Step with icon</h5>
                  </div>
                  <div class="card-body">
                    <div class="u-steps row">
                      <div class="u-step col-md-4"><span class="u-step-icon icon-shopping-cart-full" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Shopping</span></div>
                      </div>
                      <div class="u-step col-md-4 current"><span class="u-step-icon icon-notepad" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Billing</span></div>
                      </div>
                      <div class="u-step col-md-4"><span class="u-step-icon icon-time" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Getting</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>