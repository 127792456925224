<template>
     <div class="col-xl-6 col-md-12 box-col-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Bar Chart</h5>
                  </div>
                  <div class="card-body chart-block">
                    <canvas id="myBarGraph">

                    </canvas>
                  </div>
                </div>
              </div>
</template>
<script>

export default {
    
}
</script>