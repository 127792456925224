<template>
  <div class="logo-wrapper">
    <router-link to="/">
      <img class="img-fluid for-light" src="../../assets/images/logo/logo.png" alt="" />
      <img class="img-fluid for-dark" src="../../assets/images/logo/small-white-logo.png" alt="">
    </router-link>
  </div>
  <div class="toggle-sidebar">
    <div class="status_toggle sidebar-toggle d-flex" @click="toggle_sidebar">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <g>
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M21.0003 6.6738C21.0003 8.7024 19.3551 10.3476 17.3265 10.3476C15.2979 10.3476 13.6536 8.7024 13.6536 6.6738C13.6536 4.6452 15.2979 3 17.3265 3C19.3551 3 21.0003 4.6452 21.0003 6.6738Z"
              stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.3467 6.6738C10.3467 8.7024 8.7024 10.3476 6.6729 10.3476C4.6452 10.3476 3 8.7024 3 6.6738C3 4.6452 4.6452 3 6.6729 3C8.7024 3 10.3467 4.6452 10.3467 6.6738Z"
              stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M21.0003 17.2619C21.0003 19.2905 19.3551 20.9348 17.3265 20.9348C15.2979 20.9348 13.6536 19.2905 13.6536 17.2619C13.6536 15.2333 15.2979 13.5881 17.3265 13.5881C19.3551 13.5881 21.0003 15.2333 21.0003 17.2619Z"
              stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.3467 17.2619C10.3467 19.2905 8.7024 20.9348 6.6729 20.9348C4.6452 20.9348 3 19.2905 3 17.2619C3 15.2333 4.6452 13.5881 6.6729 13.5881C8.7024 13.5881 10.3467 15.2333 10.3467 17.2619Z"
              stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {

  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      activeoverlay: (state) => state.menu.activeoverlay,
    }),
  },
  methods: {
    toggle_sidebar() {
      this.$store.dispatch('menu/opensidebar');
      this.$store.state.menu.activeoverlay = false;
    },
  },
};
</script>
