import ChartData from '../../data/chart.json';

const state = {
	data : ChartData.data,
   
};


const getters = {

};

const mutations = {
	
};


const actions = {
    
	
};



export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
