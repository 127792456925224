<template>
    <div class="col-sm-12 col-xl-4">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Switch Sizing</h5>
                  </div>
                  <div class="card-body">
                    <div class="row switch-showcase">
                      <div class="col-sm-12">
                        <div class="media">
                          <label class="col-form-label">Large Size</label>
                          <div class="media-body text-end switch-lg">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Large Unhecked</label>
                          <div class="media-body text-end switch-lg">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Normal Size</label>
                          <div class="media-body text-end">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Normal Unhecked</label>
                          <div class="media-body text-end">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Small Size</label>
                          <div class="media-body text-end switch-sm">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Small Size Unhecked</label>
                          <div class="media-body text-end switch-sm">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>