<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Pearls Steps with icon</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="u-pearl done col-4">
                        <div class="u-pearl-icon"><i class="icon-shopping-cart" aria-hidden="true"></i></div><span class="u-pearl-title">Account Info</span>
                      </div>
                      <div class="u-pearl current col-4">
                        <div class="u-pearl-icon"><i class="icon-write" aria-hidden="true"></i></div><span class="u-pearl-title">Billing Info</span>
                      </div>
                      <div class="u-pearl col-4">
                        <div class="u-pearl-icon"><i class="icon-check" aria-hidden="true"></i></div><span class="u-pearl-title">Confirmation</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>