import hideMenu from "../../data/hideMenu.json"

const state = {
    hideMenu : hideMenu.hideMenu,
};

export default {
    namespaced: true,
    state,
}
