<template>
     <div class="col-sm-12 col-xl-6 col-lg-12 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Switch unchecked Outline</h5>
                  </div>
                  <div class="card-body">
                    <div class="row switch-showcase">
                      <div class="col-sm-12">
                        <div class="media">
                          <label class="col-form-label">Primary Color</label>
                          <div class="media-body text-end icon-state switch-outline">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state bg-primary"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Secondary Color</label>
                          <div class="media-body text-end icon-state switch-outline">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state bg-secondary"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Success Color</label>
                          <div class="media-body text-end icon-state switch-outline">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state bg-success"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Info Color</label>
                          <div class="media-body text-end icon-state switch-outline">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state bg-info"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Warning Color</label>
                          <div class="media-body text-end icon-state switch-outline">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state bg-warning"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Danger Color</label>
                          <div class="media-body text-end icon-state switch-outline">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state bg-danger"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>