<template>
    <Breadcrumbs main="E-Commerce" title="product"/>
    <prouctView/>
</template>
<script>
import prouctView from "./prouctView.vue"
export default {
    components:{
        prouctView
    }
}
</script>