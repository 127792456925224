import email from "../../data/email.json"

const state = {
	data : email.data,
   
};


const getters = {

};


const mutations = {
	
};


const actions = {
    
	
};



export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};