<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Vertical Step</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="u-steps u-steps-vertical">
                          <div class="u-step"><span class="u-step-number">1</span>
                            <div class="u-step-desc"><span class="u-step-title">Shopping</span>
                              <p>Choose what you want</p>
                            </div>
                          </div>
                          <div class="u-step current"><span class="u-step-number">2</span>
                            <div class="u-step-desc"><span class="u-step-title">Billing</span>
                              <p>Pay for the bill</p>
                            </div>
                          </div>
                          <div class="u-step"><span class="u-step-number">3</span>
                            <div class="u-step-desc"><span class="u-step-title">Getting</span>
                              <p>Waiting for the goods</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>