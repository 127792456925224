<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Pearls Step States</h5><span>A pearls step states with different class<code>.done</code><code>.currunt</code><code>.error</code><code>.disabled</code></span>
                  </div>
                  <div class="card-body">
                    <div class="row mb-5">
                      <div class="u-pearl current col-4">
                        <div class="u-pearl-number">1</div><span class="u-pearl-title">Account Info</span>
                      </div>
                      <div class="u-pearl disabled col-4">
                        <div class="u-pearl-number">2</div><span class="u-pearl-title">Billing Info</span>
                      </div>
                      <div class="u-pearl disabled col-4">
                        <div class="u-pearl-number">3</div><span class="u-pearl-title">Confirmation</span>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="u-pearl done col-4">
                        <div class="u-pearl-number">1</div><span class="u-pearl-title">Account Info</span>
                      </div>
                      <div class="u-pearl current col-4">
                        <div class="u-pearl-number">2</div><span class="u-pearl-title">Billing Info</span>
                      </div>
                      <div class="u-pearl disabled col-4">
                        <div class="u-pearl-number">3</div><span class="u-pearl-title">Confirmation</span>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="u-pearl done col-4">
                        <div class="u-pearl-number">1</div><span class="u-pearl-title">Account Info</span>
                      </div>
                      <div class="u-pearl current error col-4">
                        <div class="u-pearl-number">2</div><span class="u-pearl-title">Billing Info</span>
                      </div>
                      <div class="u-pearl disabled col-4">
                        <div class="u-pearl-number">3</div><span class="u-pearl-title">Confirmation</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="u-pearl done col-4">
                        <div class="u-pearl-number">1</div><span class="u-pearl-title">Account Info</span>
                      </div>
                      <div class="u-pearl done col-4">
                        <div class="u-pearl-number">2</div><span class="u-pearl-title">Billing Info</span>
                      </div>
                      <div class="u-pearl current col-4">
                        <div class="u-pearl-number">3</div><span class="u-pearl-title">Confirmation</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>