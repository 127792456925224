<template>
  <Breadcrumbs title="Citas"/>
  <div class="container-fluid list-products">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header pb-0">
          <h5>Citas registradas</h5>
        </div>
        <div class="card-body">
          <div class="table-responsive product-table">
            <form class="dataTables_wrapper no-footer">
              <div class="mb-3 row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <router-link to="/events/create" class="btn btn-primary w-100">
                    Nueva Cita
                  </router-link>
                </div>
              </div>
              <table class="display table-striped table-hover table-bordered table" id="basic-1">
                <thead>
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Tipo de consulta</th>
                    <th scope="col">Nombre paciente</th>
                    <th scope="col">Nombre médico</th>
                    <th scope="col">Especialidad</th>
                    <th scope="col">Estado consulta</th>
                    <th scope="col">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="appointment in get_rows" :key="appointment.id">
                    <td>{{ new Date(appointment.appointmentDate).toLocaleDateString('es-MX') }}</td>
                    <td>{{ appointment.consultationType }}</td>
                    <td>{{ appointment.patientName }}</td>
                    <td>{{ appointment.doctorName }}</td>
                    <td>{{ appointment.specialty }}</td>
                    <td>{{ appointment.appointmentStatus }}</td>
                    <td>
                      <button v-if="appointment.appointmentStatus != 'Realizado'" class="btn btn-primary btn-xs me-2" type="button" @click="markAsDone(appointment.id)" title="details">
                        Finalizar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul class="pagination p-2 justify-content-end">
                <li class="page-item"><a class="page-link" href="#" @click.prevent="change_page(currentPage - 1)">Anterior</a></li>
                <li class="page-item" v-for="i in num_pages()" :key="i" :class="{ 'active': i === currentPage }" @click="change_page(i)">
                  <a class="page-link" href="#">{{ i }}</a>
                </li>
                <li class="page-item"><a class="page-link" href="#" @click.prevent="change_page(currentPage + 1)">Siguiente</a></li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import Swal from 'sweetalert2';

  export default {
    data() {
      return {
        elementsPerPage: 10,
        currentPage: 1,
        appointments: [],
      };
    },
    computed: {
      get_rows() {
        const start = (this.currentPage - 1) * this.elementsPerPage;
        const end = start + this.elementsPerPage;
        return this.appointments.slice(start, end);
      },
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        const storedAppointments = localStorage.getItem('appointments');
        if (storedAppointments) {
          this.appointments = JSON.parse(storedAppointments);
        }
      },
      viewAppointmentDetails(id) {
        this.$router.push({ name: 'appointmentDetails', params: { id: id } });
      },
      change_page(page) {
        if (page > 0 && page <= this.num_pages()) {
          this.currentPage = page;
        }
      },
      num_pages() {
        return Math.ceil(this.appointments.length / this.elementsPerPage);
      },
      markAsDone(id) {
        const appointmentIndex = this.appointments.findIndex(appointment => appointment.id === id);
        if (appointmentIndex !== -1) {
          this.appointments[appointmentIndex].appointmentStatus = 'Realizado';

          Swal.fire(
              'Cerrada',
              'La cita ha sido cerrada exitosamente',
              'success'
          );

          localStorage.setItem('appointments', JSON.stringify(this.appointments));
        }
      },
    },
  };
  </script>