<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Step States</h5><span>A u-step with classname <code>.done</code> A u-step with classname <code>.error</code>A u-step with classname <code>.current</code>A u-step with classname <code>.disabled</code></span>
                  </div>
                  <div class="card-body">
                    <div class="row row-lg">
                      <div class="col-xl-3 col-lg-6">
                        <div class="u-step done bg-primary"><span class="u-step-number txt-primary">1</span>
                          <div class="u-step-desc"><span class="u-step-title">Getting</span>
                            <p class="text-light">Waiting for the goods</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-6 steps-md-mt">
                        <div class="u-step error bg-secondary"><span class="u-step-number txt-secondary">2</span>
                          <div class="u-step-desc"><span class="u-step-title">Getting</span>
                            <p class="text-light">Waiting for the goods</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-6 lg-mt">
                        <div class="u-step current bg-success"><span class="u-step-number txt-success">3</span>
                          <div class="u-step-desc"><span class="u-step-title">Getting</span>
                            <p>Waiting for the goods</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-6 lg-mt">
                        <div class="u-step disabled"><span class="u-step-number">4</span>
                          <div class="u-step-desc"><span class="u-step-title">Getting</span>
                            <p>Waiting for the goods</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>