<template>
     <div class="col-xl-3 xl-50 col-md-6 box-col-6">
                <div class="card">
                  <div class="cal-date-widget card-body">
                    <div class="cal-datepicker">
                      <div class="datepicker-here " data-language="en"> 
                        <v-calendar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>
<style scoped>
@import 'v-calendar/dist/style.css';
</style>