<template>
     <div class="col-sm-12 col-md-6 ">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5>Custom Templates</h5><span>Custom templates give you full control over how suggestions get rendered</span>
                    </div>
                    <div class="card-body">
                      <div id="custom-templates">
                        <form class="theme-form">
                          <div class="form-group">
                            <input class="form-control typeahead form-control" type="text" placeholder="Oscar winners">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="col-sm-12 col-md-6 ">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5>Multiple Sections with Headers</h5><span>Two datasets that are prefetched, stored, and searched on the client. Highlighting is enabled.</span>
                    </div>
                    <div class="card-body">
                      <div id="multiple-datasets">
                        <form class="theme-form">
                          <div class="form-group">
                            <input class="form-control typeahead form-control" type="text" placeholder="NBA and NHL teams">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
</template>