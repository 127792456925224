<template>
      <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Default Pearls Steps</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="u-pearl done col-4"><span class="u-pearl-number">1</span><span class="u-pearl-title">Account Info</span></div>
                      <div class="u-pearl current col-4"><span class="u-pearl-number">2</span><span class="u-pearl-title">Billing Info</span></div>
                      <div class="u-pearl col-4"><span class="u-pearl-number">3</span><span class="u-pearl-title">Confirmation</span></div>
                    </div>
                  </div>
                </div>
              </div>
</template>