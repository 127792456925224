<template>
     <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 footer-copyright text-center">
                <p class="mb-0">Copyright 2024 ©</p>
              </div>
            </div>
          </div>
        </footer>
</template>