<template>
     <div class="col-sm-12 col-md-6 ">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5>Default Suggestions</h5><span>Default suggestions can be shown for empty queries.</span>
                    </div>
                    <div class="card-body">
                      <div id="default-suggestions">
                        <form class="theme-form">
                          <div class="form-group">
                            <input class="form-control typeahead form-control" type="text" placeholder="NFL Teams">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 ">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5>Scrollable Dropdown Menu</h5><span>Dataset shows in scrollable dropdown menu.</span>
                    </div>
                    <div class="card-body">
                      <div id="scrollable-dropdown-menu">
                        <form class="theme-form">
                          <div class="form-group">
                            <input class="form-control typeahead form-control" type="text" placeholder="Countries">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
</template>