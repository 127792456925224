<template>
  <div class="mode animated backOutRight" :class="actives ? 'active' : ''">
    <svg class="lighticon" v-show="mixLayout == 'light-only'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg" @click="customizeMixLayout('dark-only')">
      <g>
        <g>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.1377 13.7902C19.2217 14.8742 16.3477 21.0542 10.6517 21.0542C6.39771 21.0542 2.94971 17.6062 2.94971 13.3532C2.94971 8.05317 8.17871 4.66317 9.67771 6.16217C10.5407 7.02517 9.56871 11.0862 11.1167 12.6352C12.6647 14.1842 17.0537 12.7062 18.1377 13.7902Z"
            stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
      </g>
    </svg>
    <svg class="darkicon" v-show="mixLayout == 'dark-only'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg" @click="customizeMixLayout('light-only')">
      <path
        d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z">
      </path>
      <path
        d="M18.3117 5.68834L18.4286 5.57143M5.57144 18.4286L5.68832 18.3117M12 3.07394V3M12 21V20.9261M3.07394 12H3M21 12H20.9261M5.68831 5.68834L5.5714 5.57143M18.4286 18.4286L18.3117 18.3117"
        stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </div>
</template>
<script>
export default {
  data() {
    return {
      darkMode: false,
      actives: false,
      mixLayout: 'light-only',
    };
  },
  methods: {
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.actives = !this.actives
      this.$store.dispatch('layout/setLayout', val);
    },
  },

}
</script>