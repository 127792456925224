<template>
     <div class="col-sm-12 col-xl-6 col-lg-12 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Basic Switch</h5>
                  </div>
                  <div class="card-body">
                    <div class="row switch-showcase height-equal">
                      <div class="col-sm-12">
                        <div class="media">
                          <label class="col-form-label">Default Switch</label>
                          <div class="media-body text-end">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Unhecked Switch</label>
                          <div class="media-body text-end">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">With Icon</label>
                          <div class="media-body text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Unhecked With Icon</label>
                          <div class="media-body text-end icon-state">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Disabled State</label>
                          <div class="media-body text-end">
                            <label class="switch">
                              <input type="checkbox" disabled=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="media">
                          <label class="col-form-label">Disabled With Icon</label>
                          <div class="media-body text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" disabled=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>