<template>
    <div class="col-sm-12 col-lg-6 col-xl-4 xl-50 col-md-12 box-col-6">
                <div class="card height-equal">
                  <div class="calender-widget">
                    <div class="cal-img"></div>
                    <div class="cal-date">
                      <h5>25<br>APRIL</h5>
                    </div>
                    <div class="cal-desc text-center card-body">
                      <h3>I must explain to you how? </h3>
                      <p>Denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth,Letraset sheets containing Lorem Ipsum passages, and more recently with desktop Lorem Ipsum is simply.</p>
                    </div>
                  </div>
                </div>
              </div>
</template>