<template>
    
        <router-link to="/">
                <!--  Logo here -->
        </router-link>
             
          
           
</template>
<script>
export default {
    
}
</script>