import friend from "../../data/friend.json"

const state = {
    data : friend.data,
};

export default {
    namespaced: true,
    state,
}
