<template>
     <div class="col-xl-6 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Pagination with icons</h5>
                  </div>
                  <div class="card-body">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination pagination-primary">
                        <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous"><span aria-hidden="true">«</span><span class="sr-only">Previous</span></a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next"><span aria-hidden="true">»</span><span class="sr-only">Next</span></a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
</template>