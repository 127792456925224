<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5>Step Sizing</h5><span>A step with classname <code>.u-steps-xs</code><code>.u-steps-sm</code><code>.u-steps-lg</code></span>
                  </div>
                  <div class="card-body">
                    <div class="u-steps row u-steps-xs steps-sizing-sm-mb">
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-shopping-cart" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Shopping</span></div>
                      </div>
                      <div class="u-step col-md-4 current"><span class="u-step-icon icon wb-pluse" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Billing</span></div>
                      </div>
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-time" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Getting</span></div>
                      </div>
                    </div>
                    <div class="u-steps row u-steps-sm steps-sizing-sm-mb">
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-shopping-cart" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Shopping</span></div>
                      </div>
                      <div class="u-step col-md-4 current"><span class="u-step-icon icon wb-pluse" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Billing</span></div>
                      </div>
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-time" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Getting</span></div>
                      </div>
                    </div>
                    <div class="u-steps row steps-sizing-sm-mb">
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-shopping-cart" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Shopping</span></div>
                      </div>
                      <div class="u-step col-md-4 current"><span class="u-step-icon icon wb-pluse" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Billing</span></div>
                      </div>
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-time" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Getting</span></div>
                      </div>
                    </div>
                    <div class="u-steps row u-steps-lg">
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-shopping-cart" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Shopping</span></div>
                      </div>
                      <div class="u-step col-md-4 current"><span class="u-step-icon icon wb-pluse" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Billing</span></div>
                      </div>
                      <div class="u-step col-md-4"><span class="u-step-icon icon wb-time" aria-hidden="true"></span>
                        <div class="u-step-desc"><span class="u-step-title">Getting</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>