<template>
    <div class="col-sm-12 col-md-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h5>Remote</h5><span>Remote data is only used when the data provided by local and prefetch is insufficient</span>
                    </div>
                    <div class="card-body">
                      <div id="remote">
                        <form class="theme-form">
                          <div class="form-group">
                            <input class="form-control typeahead form-control" type="text" placeholder="Choose Option">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
</template>